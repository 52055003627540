import { Box, Flex, Text } from "@chakra-ui/react"
import { featureMedia } from "./Feature"
import ImageElement from "./ImageElement"

interface ThumbnailProps {
	imageSrc: string
	text: string
	textImage?: string
	imageWidth: number
	imageHeight?: number
	videoSrc?: string
	videoUrl?: string
}

const Thumbnail = ({
	imageSrc,
	videoUrl,
	videoSrc,
	text,
	textImage,
	imageWidth,
	imageHeight = imageWidth,
}: ThumbnailProps) => {
	const dynamicMediaElement = featureMedia(
		imageSrc,
		videoUrl,
		videoSrc,
		imageWidth,
		imageHeight
	)

	return (
		<Flex
			direction="column"
			justifyContent={"flex-start"}
			alignItems={"center"}
			maxWidth="400px"
			px={8}
			py={4}
			borderRadius={8}
		>
			<Box
				mb={4}
				sx={{
					borderRadius: "xl",
					transform: "translateZ(0px)",
					WebkitTransform: "translateZ(0px)",
					overflow: "hidden",
				}}
				boxShadow="dark-lg"
			>
				{dynamicMediaElement}
			</Box>
			<Flex
				fontSize={"md"}
				mb={2}
				alignItems="center"
				justifyContent="center"
				gap={4}
				width={"75%"}
			>
				{textImage && <ImageElement imageSrc={textImage} width={28} />}
				<Text fontWeight="500" color="heading_text_dark">
					{text}
				</Text>
			</Flex>
		</Flex>
	)
}

export default Thumbnail
