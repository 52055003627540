import dynamic from "next/dynamic"
import { Box } from "@chakra-ui/react"
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false })

interface VideoElementProps {
	videoSrc?: string
	width?: number | string
	height?: number | string
	videoUrl?: string
	style?: Object
	loop?: boolean
	playing?: boolean
	muted?: boolean
	controls?: boolean
}

const VideoElement = ({
	videoUrl,
	videoSrc,
	width = 80,
	height = width ?? 80,
	style,
	loop = true,
	playing = true,
	muted = true,
	controls = false
}: VideoElementProps): JSX.Element => {
	// const [videoLoaded, setVideoLoaded] = useState<boolean>(false)

	return (
		<Box minWidth={`${width}px`}>
			<ReactPlayer
				url={videoUrl ? videoUrl : `/vid/${videoSrc}`}
				// onReady={() => setVideoLoaded(true)}
				onError={() => {
					console.error("Could not load video file: " + videoUrl)
				}}
				config={{ vimeo: { playerOptions: { responsive: true } } }}
				// fallback={image}
				// controls={true}
				controls={controls}
				loop={loop}
				playing={playing}
				muted={muted}
				width={width}
				height={height}
				style={style}
			/>
		</Box>
	)
}

export default VideoElement
