import { Box, FlexProps } from "@chakra-ui/react"

interface LandingCopyContainerProps extends FlexProps {
	sx?: object
	maxW?: number
}

const LandingCopyContainer = ({
	sx,
	maxW,
	...rest
}: LandingCopyContainerProps) => {
	const defaultStyles = {
		textAlign: "center",
		maxWidth: "90vw",
	}
	const customStyles = {
		...defaultStyles,
		...sx,
	}

	return <Box w={maxW} m="0 auto" sx={customStyles} {...rest} />
}

export default LandingCopyContainer
