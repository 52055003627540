import EnrichmentPartnerLogosIcon from "modules/pricing/icons/EnrichmentPartnerLogosIcon"
import dynamic from "next/dynamic"
import Image from "next/image"
import { useEffect, useState } from "react"
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	Link,
	SimpleGrid,
	Text,
} from "@chakra-ui/react"
import ActionElement from "@common/components/ActionElement"
import Feature from "@common/components/Feature"
import GlobalFooter from "@common/components/GlobalFooter"
import ImageElement from "@common/components/ImageElement"
import LandingContainer from "@common/components/LandingContainer"
import LandingCopyContainer from "@common/components/LandingCopyContainer"
import LandingSection from "@common/components/LandingSection"
import LandingSectionNextGen from "@common/components/LandingSectionNextGen"
import LatestNewsBanner from "@common/components/LatestNewsBanner"
import Thumbnail from "@common/components/Thumbnail"
import UserQuote from "@common/components/UserQuote"
import VideoElement from "@common/components/VideoElement"
import { LANDING_TEXT_PADDING_BOTTOM } from "@common/constants/ui"
import useIsMobile from "@common/hooks/useIsMobile"
import useViewport from "@common/hooks/useViewport"
import { SectionBg } from "@common/utils/theme"
import heroBackground from "../../../../public/img/teleseer-hero-background.png"
import interfaceHero from "../../../../public/img/teleseer-interface-hero.png"
import { Testimonial, getTestimonials } from "@cms/utils/sanityApi"

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false })

const DynamicSubscribeModal = dynamic(
	() => import("@common/components/SubscribeModal"),
	{
		ssr: false,
	}
)

const TeleseerLandingLayout = () => {
	const viewport = useViewport()
	const isMobile = useIsMobile()
	const [modalTriggerOpen, setModalTriggerOpen] = useState()
	const [modalSubmitted, setModalSubmitted] = useState()
	const [testimonials, setTestimonials] = useState<Testimonial[]>([])

	useEffect(() => {
		getTestimonials().then((data) => {
			setTestimonials(data)
		})
	}, [])

	const headingStyle = {
		fontSize: isMobile ? "lg" : "42px",
	}

	return (
		<LandingContainer position="relative" backgroundColor="#000000">
			{!isMobile && (
				<Box position="fixed" top={0} left={0}>
					<Image
						src={heroBackground}
						priority={true}
						placeholder="blur"
						alt="Teleseer - Network mapping for cybersecurity professionals"
					/>
				</Box>
			)}

			<DynamicSubscribeModal
				modalTriggerOpen={modalTriggerOpen}
				setModalTriggerOpen={setModalTriggerOpen}
				modalSubmitted={modalSubmitted}
				modalSubmittedCallback={setModalSubmitted}
			/>

			<LatestNewsBanner />

			{/* Discover and map your network... */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				justifyContent="top"
				customStyles={{
					paddingTop: isMobile ? "2rem" : "4rem",
					paddingBottom: isMobile ? "0rem" : "0rem",
				}}
			>
				<LandingCopyContainer paddingBottom="1rem">
					{!isMobile ? (
						<Heading
							fontSize={"54px"}
							fontWeight="bold"
							pb={LANDING_TEXT_PADDING_BOTTOM}
							sx={{
								bgColor: "section_color",
								bgClip: "text",
							}}
							mb={"2vh"}
						>
							Discover and map <br />
							your network in minutes.
						</Heading>
					) : (
						<Heading size={"lg"} fontWeight="extrabold" mb={isMobile ? 4 : 0}>
							Discover and map your network in minutes.
						</Heading>
					)}
					{/* <Text fontSize="md" pt={8} pb={LANDING_TEXT_PADDING_BOTTOM}>
						Or whatever else you have.
					</Text> */}
					<Center fontSize={20} fontWeight={300}>
						Next-gen packet analysis and network visualization.
						<br />
						All from your web browser.
					</Center>
				</LandingCopyContainer>
				<Flex
					alignItems={isMobile ? "center" : "flex-start"}
					justifyContent={"center"}
					gap={6}
					direction={isMobile ? "column" : "row"}
				>
					<Box>
						<a
							href="https://go.teleseer.com/auth/register/trial"
							target="_blank"
							style={{ textDecoration: "none" }}
						>
							<Button
								type="submit"
								borderRadius={24}
								zIndex="2"
								mt={4}
								px={10}
								size="lg"
								sx={{
									color: "heading_text",
									backgroundColor: "action_blue",
									_hover: {
										backgroundColor: "blue.400",
									},
								}}
							>
								Sign up for free
							</Button>
						</a>
						<Center fontSize={14} color="section_color_light" mt={3}>
							No credit card required.
						</Center>
					</Box>
				</Flex>
			</LandingSection>

			{/* Hero video */}
			<LandingSection>
				<Box
					sx={{
						outline: `2px solid #696969`,
						outlineOffset: "-1px",
						borderRadius: "xl",
						transform: "translateZ(0px)",
						WebkitTransform: "translateZ(0px)",
						overflow: "hidden",
						boxShadow:
							"rgba(113, 131, 149, 0.5) 0px 0px 36px 0px, rgba(113, 131, 149, 0.25) 0px 0px 0px 1px",
					}}
				>
					<ReactPlayer
						// url={"https://vimeo.com/826536291/"}
						url="/vid/teleseer-hero.mp4"
						// onReady={() => setVideoLoaded(true)}
						// onError={() => {
						// 	console.error("Could not hero file")
						// }}
						// config={{ vimeo: { playerOptions: { responsive: true } } }}
						// fallback={image}
						// controls={true}
						controls={true}
						loop={false}
						playing={true}
						muted={true}
						// volume={0.05}
						light={<Image src={interfaceHero} alt="Teleseer hero" />}
						width={Math.min(viewport.visualWidth * 0.85, 1000)}
						height={Math.min(viewport.visualWidth * 0.85, 1000) * 0.4567}
						// controlsList="nodownload"
					/>
				</Box>
			</LandingSection>

			{/* People who like us */}
			{/* <LandingSection sectionBgColor="light">
				<Center
					mb={6}
					fontSize={16}
					color="section_color_light"
					fontWeight="500"
				>
					Powering teams at:
				</Center>
				<Flex gap={4}>
					<Text>Item 1</Text>
					<Text>Item 2</Text>
					<Text>Item 3</Text>
					<Text>Item 4</Text>
				</Flex>
			</LandingSection> */}

			{/* One-click visibility */}
			<LandingSection sectionBgColor={SectionBg.white} borderRadius={"none"}>
				<Flex
					gap={12}
					alignItems="center"
					justifyContent="center"
					textAlign="left"
					direction={isMobile ? "column" : "row"}
					width="90%"
				>
					<Box
						maxWidth={{ base: "50%", xs: "100%", md: "380px" }}
						ml="0"
						textAlign={{ base: "center", md: "left" }}
						color={"section_color_dark"}
					>
						<Heading style={headingStyle} mb={"4vh"}>
							One-click visibility.
						</Heading>
						<Text fontSize="md" pb={LANDING_TEXT_PADDING_BOTTOM}>
							Teleseer is the fastest, most powerful way to analyze any network
							from packet captures.
							<br />
							<br />
							No infrastructure, database, setup, or code needed. Start using
							instantly in your browser.
							<br />
							<br />
							{/* <Link href="https://go.teleseer.com" color="hyperlink">
								Learn more &#8594;
							</Link> */}
						</Text>
					</Box>
					<Box
						maxWidth={{ base: "50%", xs: "100%", md: "375px" }}
						borderRadius={8}
						marginLeft={{ base: 0, lg: "16" }}
						overflow="hidden"
						boxShadow="dark-lg"
					>
						<VideoElement
							videoSrc={"drag-drop-teleseer.mp4"}
							width="100%"
							height="100%"
							style={{ objectFit: "fill" }}
						/>
					</Box>
				</Flex>
			</LandingSection>

			{/* Know your network faster */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.lighter}
				color="section_color_dark"
			>
				<Heading
					style={headingStyle}
					mb={"8vh"}
					justifyContent={isMobile ? "center" : ""}
					textAlign="center"
				>
					Know your network faster.
				</Heading>
				<SimpleGrid columns={{ sm: 2, md: 3 }} spacing={10} mb={10}>
					<Feature
						headingText="Passive Asset Discovery"
						text="No agents, scanning or appliances. Find unmanaged devices and
						shadow IT the other tools miss."
						imageSrc="icons/icon-telescope-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
					/>
					<Feature
						headingText="Automated Analysis"
						text="Gain a deep understanding of your network with minimal setup, saving time and effort."
						imageSrc="icons/icon-analytics-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
					/>
					<Feature
						headingText="Threat Insights"
						text="Hunt threats and anomalies faster with built-in intelligence feeds."
						imageSrc="icons/icon-threat-detection-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
						bonusContent={
							<EnrichmentPartnerLogosIcon style={{ maxWidth: "90%" }} />
						}
					/>
					<Feature
						headingText="Better Network Forensics"
						text="Find more threat patterns and anomalies by visualizing your PCAP data."
						imageSrc="icons/icon-network-forensics-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
					/>
					<Feature
						headingText="Faster Incident Response"
						text="Speed up investigations by streamlining your network traffic analysis workflow."
						imageSrc="icons/icon-incident-response-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
					/>
					<Feature
						headingText="Stronger OT Security"
						text="See and know everything that's happening on your OT networks with zero disruption."
						imageSrc="icons/icon-robot-arm-blue.svg"
						imageHeight={"80px"}
						maxWidth={"320px"}
					/>
				</SimpleGrid>
				{/* <Center>
					<Link href="https://go.teleseer.com" color="hyperlink">
						See use cases &#8594;
					</Link>
				</Center> */}
			</LandingSection>

			{/* Be more productive */}
			<LandingSection
				fullWidth
				maxWidth="1100px"
				borderRadius={"none"}
				sectionBgColor={SectionBg.lighter}
				color="section_color_dark"
				customStyles={{ pt: 0 }}
			>
				<Heading
					style={headingStyle}
					mb={4}
					justifyContent={isMobile ? "center" : ""}
					textAlign="center"
				>
					Be more productive.
				</Heading>
				<Center textAlign={"center"} mb={10}>
					Point-and-click workflows to help you get answers
					<br />
					from PCAP faster and easier than ever before.
				</Center>
				<Flex
					flexDirection="row"
					flexWrap={"wrap"}
					justifyContent={"center"}
					gap={10}
					mb={10}
					w="100%"
				>
					<Feature
						headingText="Precision Timeline"
						text={
							<>
								See when key events happen. <br /> No more drowning in
								timestamps.
							</>
						}
						imageSrc="timeline-zoom-explore.png"
						videoSrc="timeline-zoom-explore.mp4"
						imageLocation="bottom"
						imageHeight={240}
						minWidth="350px"
					/>
					<Feature
						headingText="Selection Tracking"
						text={
							<>
								Visual context helps you stay <br />
								focused during investigations.
							</>
						}
						imageSrc="inventory-smart-follow-selection-thumbnail.png"
						videoSrc="inventory-smart-follow-selection.mp4"
						imageLocation="bottom"
						minWidth="350px"
						imageHeight={240}
					/>
					<Feature
						headingText="Network Mapping"
						text={
							<>
								Understand the structure and
								<br /> relationships on any network.
							</>
						}
						imageSrc="map-layout-change-thumbnail.png"
						videoSrc="map-layout-change.mp4"
						imageLocation="bottom"
						minWidth="350px"
						imageHeight={240}
					/>
					<Feature
						headingText="Smart Filtering"
						text={
							<>
								Instantly identify traffic and
								<br /> events for specific devices.
							</>
						}
						imageSrc="timeline-smart-filter.png"
						videoSrc="timeline-smart-filter.mp4"
						imageLocation="bottom"
						minWidth="350px"
						imageHeight={240}
					/>
					<Feature
						headingText="Spotlight Search"
						text={
							<>
								Find devices matching
								<br /> different attributes easily.
							</>
						}
						imageSrc="spotlight_search.png"
						videoSrc="map-spotlight-search.mp4"
						imageLocation="bottom"
						minWidth="350px"
						imageHeight={240}
					/>
					<Feature
						headingText="Threat Tags"
						text={
							<>
								Never waste time searching
								<br /> for info about IPs again.
							</>
						}
						imageSrc="internet-host-inspector.png"
						videoSrc="internet-host-inspector.mp4"
						imageLocation="bottom"
						minWidth="350px"
						imageHeight={240}
					/>
				</Flex>
			</LandingSection>

			{/* Download just the packets you need */}
			<LandingSection
				fullWidth
				maxWidth="1100px"
				borderRadius={"none"}
				sectionBgColor={SectionBg.white}
				color="section_color_dark"
			>
				<Heading
					style={headingStyle}
					mb={"4vh"}
					justifyContent={isMobile ? "center" : ""}
					textAlign="center"
				>
					Download just the packets you need.
				</Heading>
				<Center textAlign={"center"} mb={10}>
					PCAP too large for Wireshark and other tools?
					<br />
					Teleseer lets you carve and extract just what you need.
				</Center>
				<Center boxShadow="dark-lg">
					<VideoElement
						videoSrc={"packet-carving.mp4"}
						width="100%"
						height="100%"
					/>
				</Center>
			</LandingSection>

			{/* Analyze captures */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.blue}
				color="section_color_dark"
				// my={"4vh"}
			>
				<Flex
					direction={isMobile ? "column" : "row"}
					gap={20}
					alignItems="center"
				>
					<Heading
						fontSize="54px"
						fontWeight={700}
						justifyContent={isMobile ? "center" : ""}
						textAlign={isMobile ? "center" : "left"}
						color="#22364C"
					>
						Analyze captures
						<br /> 100s of GB in size.
					</Heading>
					<Box>
						<Flex gap={6}>
							<ImageElement
								imageSrc={"pcap-shark.svg"}
								width={108}
								height={108}
							/>
							<ImageElement
								imageSrc={"zeek-logo.svg"}
								width={108}
								height={108}
							/>
						</Flex>
						<Text textAlign="center" fontWeight={600} mt={2}>
							PCAP and Zeek supported.
						</Text>
					</Box>
				</Flex>
			</LandingSection>

			{/* No more busy work */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.light_blue}
				color="section_color_dark"
			>
				<Heading
					style={headingStyle}
					mb={4}
					justifyContent={isMobile ? "center" : ""}
					textAlign="center"
				>
					Say goodbye to busy work.
				</Heading>
				<Center textAlign={"center"} mb={10} fontSize="lg">
					Teleseer removes repetitive mental tasks so <br />
					you can focus on higher-value analysis.
				</Center>
				<SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={10}>
					<Thumbnail
						imageSrc="wireshark.png"
						text="Searching through dense packet data."
						textImage="icons/icon-magnifying-glass.svg"
						imageWidth={240}
						videoSrc="wireshark-packets.mp4"
					/>
					<Thumbnail
						imageSrc="terminal_logs.png"
						text="Correlating between different event logs."
						textImage="icons/icon-blocks-123.svg"
						imageWidth={240}
						videoSrc="zeek-logs.mp4"
					/>
					<Thumbnail
						imageSrc="whiteboard.png"
						text="Visualizing network assets and connections."
						textImage="icons/icon-connection-interface.svg"
						imageWidth={240}
						videoSrc="whiteboard-network-map-drawing.mp4"
					/>
				</SimpleGrid>
			</LandingSection>

			{/* User quotes */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.dark}
				color="section_color"
			>
				<Flex
					gap="6"
					wrap={"wrap"}
					justifyContent="center"
					alignItems={"center"}
					direction={"column"}
				>
					<UserQuote
						headingText="This tool is an absolute game changer!"
						subtext="I had a beautiful diagram in ~3 minutes."
						userImageSrc="users/allison_roberts.png"
						username="Allison Roberts"
						userPosition="Information Security &amp; Risk Manager"
					/>
					<Divider my={6} />
					<Flex
						gap={10}
						justifyContent="center"
						wrap="wrap"
						px={8}
						width="100%"
					>
						<ActionElement
							imageSrc="icons/icon-flask-chemical.svg"
							headingText="Explore demo"
							text={
								<>
									Experiment with demo data in
									<br /> our read-only environment.
								</>
							}
							actionText={"Explore demo"}
							actionHref={"https://demo.teleseer.com/"}
							actionColor={"action_green"}
							borderColor="section_dark_border"
						/>
						<ActionElement
							imageSrc="icons/icon-rocket-ship.svg"
							headingText="Try for Free"
							text={
								<>
									Try Teleseer for 7-days
									<br /> on your own data.
								</>
							}
							actionText={"Sign up now"}
							actionHref={"https://go.teleseer.com/auth/register/trial"}
							actionColor={"action_blue"}
							borderColor="section_dark_border"
						/>
					</Flex>
					<Link
						href={"mailto:hello@cyberspatial.com"}
						target="_blank"
						color="hyperlink"
						fontSize={20}
						mt={8}
					>
						Contact us &#8594;
					</Link>
				</Flex>
			</LandingSection>

			{/* Analyze data from any... */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.white}
				color="section_color_dark"
			>
				<Flex
					direction="column"
					alignItems={"center"}
					textAlign="center"
					fontSize={16}
					width={{ base: "90%", md: "inherit" }}
				>
					<Heading
						style={headingStyle}
						mb={4}
						justifyContent={isMobile ? "center" : ""}
						textAlign="center"
					>
						Analyze data from any source.
					</Heading>
					<Text>
						Collect traffic from a variety of ways <br />
						for analysis using Teleseer.
					</Text>
					{/* <Link
						href="https://support.teleseer.com/docs/supported-file-types"
						color="hyperlink"
						isExternal
					>
						Learn more &#8594;
					</Link> */}
					<Box
						maxWidth={{ base: "95%", md: "800px" }}
						overflow="hidden"
						// boxShadow="dark-lg"
					>
						<VideoElement
							videoSrc={"how-it-works-capture-mobile.mp4"}
							width="100%"
							height="100%"
						/>
					</Box>

					{/* <Box
						sx={{
							outline: `2px solid #111111`,
							outlineOffset: "-1px",
							borderRadius: "xl",
							transform: "translateY(0px)",
							"-webkit-transform": "translateZ(0px)",
							overflow: "hidden",
						}}
					>
						<ReactPlayer
							// url={"https://vimeo.com/826536291/"}
							url="/vid/teleseer-hero.webm"
							// onReady={() => setVideoLoaded(true)}
							// onError={() => {
							// 	console.error("Could not hero file")
							// }}
							// config={{ vimeo: { playerOptions: { responsive: true } } }}
							// fallback={image}
							// controls={true}
							controls={true}
							loop={true}
							playing={true}
							muted={true}
							width={Math.min(viewport.visualWidth * 0.85, 1000)}
							height={Math.min(viewport.visualWidth * 0.85, 1000) * 0.4567}
							// controlsList="nodownload"
						/>
					</Box> */}
				</Flex>
			</LandingSection>

			{/* Powerful performance */}
			<LandingSection
				fullWidth
				borderRadius={"none"}
				sectionBgColor={SectionBg.lighter}
				color="section_color_dark"
			>
				<Heading
					style={headingStyle}
					mb={4}
					justifyContent={isMobile ? "center" : ""}
					textAlign="center"
				>
					Powerful performance.
				</Heading>
				<Center textAlign={"center"} mb={10}>
					Teleseer identifies over 5,000 protocols and apps for the deepest
					level of visibility and analysis.
				</Center>
				<Flex direction={{ base: "column", xl: "row" }} gap={10} mb={10}>
					<Box position={"relative"}>
						<Box position="absolute" zIndex="99" color="white" top={8} left={9}>
							<Heading size={isMobile ? "sm" : "lg"} mb={4} fontWeight="500">
								Protocol Coverage
							</Heading>
							<Text fontSize={isMobile ? "12" : "inherit"} mb={4}>
								Identify thousands of protocols and <br /> apps that otherwise
								show up as
								<br /> generic connections.
							</Text>
							{/* <Link href="https://go.teleseer.com" color="hyperlink">
								Learn more &#8594;
							</Link> */}
						</Box>
						<ImageElement
							imageSrc={"protocol_coverage.png"}
							width={isMobile ? 543 * 0.6 : 543}
							height={isMobile ? 483 * 0.6 : 483}
						/>
					</Box>
					<Box position={"relative"}>
						<Box position="absolute" zIndex="99" color="white" top={8} left={9}>
							<Heading size={isMobile ? "sm" : "lg"} mb={4} fontWeight="500">
								Engine Speed
							</Heading>
							<Text fontSize={isMobile ? "12" : "inherit"} mb={4}>
								No more waiting around for tools <br />
								to finish or fail when analyzing
								<br /> large datasets.
							</Text>
							{/* <Link href="https://demo.teleseer.com" color="hyperlink">
								See test data &#8594;
							</Link> */}
						</Box>
						<ImageElement
							imageSrc={"engine_speed.png"}
							width={isMobile ? 543 * 0.6 : 543}
							height={isMobile ? 483 * 0.6 : 483}
						/>
					</Box>
				</Flex>
			</LandingSection>

			<LandingSectionNextGen />

			<GlobalFooter />
		</LandingContainer>
	)
}

export default TeleseerLandingLayout
