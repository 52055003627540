import { motion } from "framer-motion"
import React from "react"
import { Box, Button, Flex, Link, Text } from "@chakra-ui/react"
import {
	MAGIC_BORDER_GRADIENT_RED,
	RED_GRADIENT,
	RED_GRADIENT_HOVER,
} from "@common/constants/ui"
import useIsMobile from "@common/hooks/useIsMobile"
import useViewport from "@common/hooks/useViewport"

const LatestNewsBanner = () => {
	return null

	const viewport = useViewport()
	const isMobile = useIsMobile()

	const borderWidth = 2
	const height = 120
	const width = Math.min(420, viewport.visualWidth - 40)

	return (
		<motion.div
			initial={{
				scale: 1.5,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			transition={{
				type: "spring",
				damping: 8,
				restSpeed: 0.001,
				bounce: 0.75,
			}}
		>
			<Box
				height={`${height}px`}
				width={`${width}px`}
				mx="auto"
				my="1rem"
				mt={isMobile ? "2rem" : "6.5rem"}
				style={{
					backgroundImage: MAGIC_BORDER_GRADIENT_RED,
					paddingTop: `${borderWidth}px`,
					paddingLeft: `${borderWidth}px`,
				}}
				borderRadius="lg"
				boxShadow="dark-lg"
			>
				<Flex
					height={`${height - borderWidth * 2}px`}
					width={`${width - borderWidth * 2}px`}
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					backgroundColor="bg_dark"
					borderRadius="lg"
					zIndex={1}
					margin="0 !important"
				>
					<Box>
						<Text fontSize="md" pb={2}>
							🚀&nbsp;&nbsp;We&apos;re live on Indiegogo!
						</Text>
						<Box mb={2}>
							<Link
								href="https://www.indiegogo.com/projects/teleseer-powerful-fast-network-mapping-software"
								target="_blank"
								rel="noreferrer"
								_hover={{
									bgGradient: RED_GRADIENT_HOVER,
								}}
							>
								<Button
									fontSize="lg"
									fontWeight={600}
									bgGradient={RED_GRADIENT}
									_hover={{
										bgGradient: RED_GRADIENT_HOVER,
									}}
									// bgClip="text"
									// as="span"
								>
									Join the campaign
								</Button>
							</Link>
						</Box>
					</Box>
				</Flex>
			</Box>
		</motion.div>
	)
}

export default LatestNewsBanner
