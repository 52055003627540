import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import { LANDING_TEXT_PADDING_BOTTOM } from "@common/constants/ui"
import useIsMobile from "@common/hooks/useIsMobile"
import { SectionBg } from "@common/utils/theme"
import ImageElement from "./ImageElement"
import LandingSection from "./LandingSection"

const LandingSectionNextGen = () => {
	const isMobile = useIsMobile()
	const headingStyle = {
		fontSize: isMobile ? "lg" : "42px",
	}

	return (
		<LandingSection
			fullWidth
			borderRadius={"none"}
			sectionBgColor={SectionBg.lighter}
			color="section_color_dark"
			// mt={isMobile ? "" : "-5rem"}
		>
			<Flex
				// background="white"
				direction={isMobile ? "column" : "row"}
				// p={isMobile ? 4 : 16}
				// borderRadius={6}
				alignItems="center"
				justifyContent="space-around"
				width={"80%"}
				// boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
			>
				<Box>
					<Heading
						style={headingStyle}
						mb={4}
						pb={LANDING_TEXT_PADDING_BOTTOM}
						textAlign={isMobile ? "center" : "left"}
					>
						Next-gen packet analysis <br />
						for IT & security teams.
					</Heading>
					<Text textAlign={isMobile ? "center" : "left"}>
						Try on your own data for free.
						<br />
						No credit card required.
						<br />
						<br />
						<a
							href={"https://go.teleseer.com/auth/register/trial"}
							target="_blank"
							style={{
								textDecoration: "none",
							}}
						>
							<Button
								variant="outline"
								size="xs"
								borderRadius={16}
								height={8}
								px={6}
								sx={{
									color: "heading_text",
									backgroundColor: "action_blue",
									_hover: {
										filter: "brightness(110%)",
									},
								}}
							>
								Try Now
							</Button>
						</a>
					</Text>
				</Box>
				<Box mt={isMobile ? "3rem" : ""}>
					<ImageElement
						imageSrc={"teleseer-circle.png"}
						width={isMobile ? 280 : 320}
						// height={280, 320}
					/>
				</Box>
			</Flex>
			{/* <Center>
					<Link
						href={"mailto:hello@cyberspatial.com"}
						target="_blank"
						color="hyperlink"
						fontSize={20}
						mt={8}
					>
						Contact us &#8594;
					</Link>
				</Center> */}
		</LandingSection>
	)
}

export default LandingSectionNextGen
