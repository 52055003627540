import { Box, Flex } from "@chakra-ui/react"
import ImageElement from "./ImageElement"

interface FeatureProps {
	bgColor?: string
	headingText: string
	headerSize?: number
	text: string | JSX.Element
	textAlign?: string
	textSize?: number
	lineHeight?: string
	imageSrc?: string
	imageLocation?: "top" | "bottom" | "left" | "right"
	imageWidth?: number | string
	imageHeight?: number | string
	videoSrc?: string
	videoUrl?: string
	minWidth?: number | string
	maxWidth?: number | string
	bonusContent?: string | JSX.Element
}

export const featureMedia = (
	imageSrc,
	videoUrl,
	videoSrc,
	imageWidth,
	imageHeight
) => {
	if (videoUrl || videoSrc) {
		return (
			<video
				src={`/vid/${videoSrc}`}
				autoPlay={true}
				controls={false}
				loop={true}
				muted={true}
				style={{
					width: "100%",
					height: "100%",
					objectFit: "cover",
				}}
			></video>
		)
	}

	return (
		<ImageElement imageSrc={imageSrc} width={imageWidth} height={imageHeight} />
	)
}

const Feature = ({
	bgColor = "white",
	headingText,
	headerSize = 20,
	text,
	textAlign = "center",
	textSize = 16,
	lineHeight,
	imageSrc,
	imageLocation = "top",
	imageWidth = "100%",
	imageHeight = 120,
	minWidth = null,
	maxWidth = "540px",
	videoUrl,
	videoSrc,
	bonusContent,
}: FeatureProps) => {
	const dynamicMediaElement = featureMedia(
		imageSrc,
		videoUrl,
		videoSrc,
		imageWidth,
		imageHeight
	)

	return (
		<Flex
			direction="column"
			flex={1}
			minWidth={minWidth}
			justifyContent={"flex-start"}
			alignItems={"center"}
			maxWidth={maxWidth}
			backgroundColor={bgColor}
			px={6}
			py={4}
			borderRadius={8}
			boxShadow={"md"}
		>
			{imageSrc && imageLocation === "top" && (
				<Box mt={4} mb={2}>
					{dynamicMediaElement}
				</Box>
			)}
			<Box
				fontWeight="700"
				fontSize={headerSize}
				mt={2}
				mb={2}
				sx={{ textAlign: textAlign }}
				width="100%"
			>
				{headingText}
			</Box>
			<Box
				fontSize={textSize}
				mb={2}
				sx={{ textAlign: textAlign }}
				lineHeight={lineHeight ?? "inherit"}
			>
				{text}
			</Box>
			{bonusContent && bonusContent}
			{imageSrc && imageLocation === "bottom" && (
				<Box
					mt={8}
					mb={-4}
					sx={{
						width: "100%",
						maxWidth: imageWidth,
						height: imageHeight,
						borderTopRadius: "xl",
						transform: "translateZ(0px)",
						WebkitTransform: "translateZ(0px)",
						overflow: "hidden",
					}}
				>
					{dynamicMediaElement}
				</Box>
			)}
		</Flex>
	)
}

export const FeatureHorizontal = ({
	headingText,
	headerSize = 20,
	text,
	textAlign = "center",
	textSize = 16,
	imageSrc,
	imageLocation = "left",
	imageWidth,
	imageHeight,
	maxWidth = 512,
	videoUrl,
	videoSrc,
}: FeatureProps) => {
	const dynamicMediaElement = featureMedia(
		imageSrc,
		videoUrl,
		videoSrc,
		imageWidth,
		imageHeight
	)

	return (
		<Flex
			direction="row"
			justifyContent={"center"}
			alignItems={"center"}
			maxWidth={maxWidth}
			backgroundColor={"white"}
			px={8}
			py={4}
			borderRadius={8}
			boxShadow={"md"}
		>
			{imageSrc && imageLocation === "left" && (
				<Box mr={6} minWidth={`${imageWidth}px`}>
					{dynamicMediaElement}
				</Box>
			)}
			<Box>
				<Box
					fontWeight="700"
					fontSize={headerSize}
					mb={2}
					sx={{ textAlign: textAlign }}
				>
					{headingText}
				</Box>
				<Box fontSize={textSize} sx={{ textAlign: textAlign }}>
					{text}
				</Box>
			</Box>
			{imageSrc && imageLocation === "right" && (
				<Box ml={6} minWidth={`${imageWidth}px`}>
					{dynamicMediaElement}
				</Box>
			)}
		</Flex>
	)
}

export default Feature
