import Head from "next/head"
import React from "react"
import GlobalHeader from "@common/components/GlobalHeader"
import TeleseerLandingLayout from "@teleseer/layouts/TeleseerLandingLayout"

const Index = () => {
	return (
		<>
			<Head>
				<title>
					Teleseer | Smarter Packet Analysis for IT & Security Teams
				</title>
			</Head>

			<GlobalHeader />

			<TeleseerLandingLayout />
		</>
	)
}

export default Index
