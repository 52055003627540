import { Box, Flex, Text } from "@chakra-ui/react"
import useIsMobile from "@common/hooks/useIsMobile"
import ImageElement from "./ImageElement"

interface UserQuoteProps {
	headingText?: string
	subtext?: string
	userImageSrc?: string
	username?: string
	userPosition?: string
}

const UserQuote = ({
	headingText = "This tool rocks!",
	subtext = "Where has it been all my life?",
	userImageSrc = "users/avatar_default.png",
	username = "Frank Grimes",
	userPosition = "Supervisor at Springfield Nuclear Power Plant",
}: UserQuoteProps) => {
	const isMobile = useIsMobile()

	return (
		<Flex
			justifyContent={"center"}
			alignItems={"flex-start"}
			color="white"
			px={8}
			py={4}
			gap={{ base: 4, sm: 8, lg: 20 }}
			width={isMobile ? "100%" : "66%"}
			wrap={isMobile ? "wrap" : "nowrap"}
		>
			<Box>
				<ImageElement imageSrc="icons/icon-quotation.svg" />
			</Box>
			<Box>
				<Text fontSize="54px" fontWeight="700" lineHeight={"60px"} mb={8}>
					{headingText}
				</Text>
				<Text
					fontWeight="300"
					fontSize={"20px"}
					mb={6}
					width={isMobile ? "100%" : "75%"}
				>
					&ldquo;{subtext}&rdquo;
				</Text>
				<Flex gap={4}>
					<ImageElement imageSrc={userImageSrc} width={60} height={60} />
					<Box>
						<Text fontWeight="500" fontSize={"20px"}>
							{username}
						</Text>
						<Text fontWeight="300" fontSize={"20px"} mt={-1}>
							{userPosition}
						</Text>
					</Box>
				</Flex>
			</Box>
		</Flex>
	)
}

export default UserQuote
