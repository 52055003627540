/* eslint-disable react/jsx-no-target-blank */
import { Box, Button, Center, Flex, Text } from "@chakra-ui/react"
import ImageElement from "./ImageElement"

interface ActionElementProps {
	headingText: string
	text: string | JSX.Element
	imageSrc: string
	imageLocation?: "top" | "bottom"
	actionText: string
	actionHref: string
	actionColor: string
	actionSubtext?: string
	borderColor?: string
}

const ActionElement = ({
	headingText,
	text,
	imageSrc,
	imageLocation = "top",
	actionText,
	actionHref,
	actionColor,
	actionSubtext,
	borderColor,
}: ActionElementProps) => {
	return (
		<Flex
			direction="column"
			justifyContent={"center"}
			alignItems={"center"}
			maxWidth="420px"
			backgroundColor={"bg_quote"}
			px={12}
			py={16}
			borderRadius={16}
			width="100%"
			border={borderColor ? "1px solid #000" : ""}
			borderColor={borderColor ?? ""}
		>
			{imageLocation === "top" && (
				<Box mb={4}>
					<ImageElement imageSrc={imageSrc} />
				</Box>
			)}
			<Box fontWeight="600" fontSize={24} mb={2} textAlign="center">
				{headingText}
			</Box>
			<Box fontSize={16} textAlign="center" mb={4}>
				{text}
			</Box>
			<Box>
				<Center>
					<a
						href={actionHref}
						target="_blank"
						style={{
							textDecoration: "none",
						}}
					>
						<Button
							size="xs"
							borderRadius={16}
							height={8}
							px={6}
							sx={{
								color: "heading_text",
								backgroundColor: actionColor,
								_hover: {
									filter: "brightness(110%)",
								},
							}}
						>
							{actionText}
						</Button>
					</a>
				</Center>
				<Center fontSize={14} color="section_color_light" mt={3}>
					{actionSubtext}
				</Center>
			</Box>
			{imageLocation === "bottom" && (
				<Box mt={4}>
					<ImageElement imageSrc={imageSrc} />
				</Box>
			)}
		</Flex>
	)
}

export default ActionElement
